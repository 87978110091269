<template>
  <div class="creator-step">
    <div class="creator-step-title">
      <span>{{ $locale["accept_terms"] }}</span>
      <a target="_blank" href="/terms#terms-use-all-users" class="primary-circle"><iconic name="external" /></a>
    </div>
    <div v-if="isStep1Complete">
      <div class="creator-step-content">
        <UserTerms />
      </div>
      <div class="terms-accept">
        <ToggleSwitchLight :label="$locale['accept_user_terms_desc']" :value="isAcceptedTerms ? 'on' : ''" @change="toggleTerms" />
      </div>
      <div :class="`creator-step-options ${isAcceptedTerms ? '__accepted' : '__disabled'}`">
        <GradientButton :label="$locale['words']['continue']" to="/user/verify?apply=step3" icon="arrowRight" dir="rtl" />
      </div>
    </div>
    <div v-else>
      <div class="creator-step-content">
        {{ $locale.words["plaease_complete"] + " " + $locale.words["step"] + " 1" }}
      </div>
      <div class="creator-step-options">
        <GradientButton :label="$locale['words']['go_back']" to="/user/verify?apply=step1" icon="arrowLeft" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    UserTerms: () => import("../user/UserTerms.vue"),
    ToggleSwitchLight: () => import("../inputs/ToggleSwitchLight.vue"),
  },
  methods: {
    toggleTerms: function(value) {
      this.$store.commit("setApplyStep", { step: "step2", value: value === "on" ? true : false });
    },
  },
  computed: {
    isCompleteProfile: function() {
      return this.$user.user && this.$user.name && this.$user.describe && this.$user.country && this.$user.birthday;
    },
    isAcceptedTerms: function() {
      return this.$store.state.creator.apply.step2;
    },
    isStep1Complete: function() {
      return this.$store.state.creator.apply.step1;
    },
  },
};
</script>
